<template>
  <div class="groupManagement">

       <el-tabs v-model="activeName" type="card" @tab-click="handleClick" >
    <el-tab-pane :label="item" :name="`${index}`" v-for="(item,index) in groupList" :key='index'></el-tab-pane>

  </el-tabs>
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center mt20">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
           <el-input v-model="form.name" :placeholder='`请输入${groupList[active]}名称`' style="width:160px;" clearable v-if="active==0">
           </el-input>
        <el-input v-model="form.userId" :placeholder='`请输入${groupList[active]}ID`' style="width:160px;" clearable v-else>
        </el-input>
        <el-input v-model="form.tel" :placeholder='`请输入${groupList[active]}电话`' style="width:160px;" clearable class="ml20"></el-input>

      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="key" label="序号" width="180"></el-table-column>
         <el-table-column prop="userId" :label="groupList[active]+'Id'" v-if="active!=0"></el-table-column>
        <el-table-column prop="name" label="代理商"  v-if="active==0"></el-table-column>
         <el-table-column prop="deep" label="推荐类型"></el-table-column>
        <el-table-column prop="tel" label="电话"></el-table-column>
        <el-table-column prop="groupTotalNumber" label="团队总人数"></el-table-column>
        <el-table-column prop="subAgentNumber" label="下级代理数" v-if="active==0"></el-table-column>
        <el-table-column prop="expertNumber" label="达人数"></el-table-column>
        <el-table-column prop="memberNumber" label="会员数"></el-table-column>
        <el-table-column prop="visitorNumber" label="游客数"></el-table-column>
        <!-- <el-table-column prop="createTime" label="游客数" width="160">
          <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column> -->
          <el-table-column prop="shopNumber" label="商户数"  v-if="active==0"></el-table-column>
        <el-table-column prop="profitTotal" label="总分润"></el-table-column>

        <el-table-column prop="withdrawalTotal" label="可提现" align="center" width="120">
        </el-table-column>

      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>

  </div>
</template>
<script>
import {
  $sg_totalDetail,
} from '@/api/buyRebate';

const groupList = ['代理', '达人', '会员', '游客'];
export default {
  name: 'GroupManagementDetail',
  data() {
    return {
      form: {
        userId: '',
        tel: '',
        name: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      orderAmount: 0,
      orderNum: 0,
      orderVisible: true,
      groupList,
      active: 0,
      activeName: null,
      param: { },
      agentuserData: {},
    };
  },
  created() {
    this.agentuserData = this.$route.query;
    this.param = { agentSid: this.agentuserData.agentSid, type: 3 };
    this.getDetailData(this.param);
  },
  mounted() {

  },
  methods: {

    // 获取详情
    getDetailData(param = {}) {
      $sg_totalDetail(param).then((res) => {
        res.records.forEach((item) => {
          if (item.profitTotal == null) {
            item.profitTotal = 0;
          }
        });
        this.tableData = res.records;
        if (this.tableData && this.tableData.length) {
          this.tableData.forEach((item, index) => {
            item.key = Number.parseInt(index + 1 + (this.currentPage - 1) * 15, 0);
          });
        }
        this.totalPage = res.pages;
      });
    },

    handleClick(tab, event) {
      console.log(tab, event.target.id);
      const tabId = event.target.id;
      const indexRegular = /(?<=-)\d{1}/;
      this.active = tabId.match(indexRegular)[0];
      switch (+this.active) {
        case 0:
          this.param = { agentSid: this.agentuserData.agentSid, type: 3 };
          break;
        case 1:
          this.param = { id: this.agentuserData.id, type: 2, agentSid: this.agentuserData.agentSid };
          break;
        case 2:
          this.param = { id: this.agentuserData.id, type: 1, agentSid: this.agentuserData.agentSid };
          break;
        case 3:
          this.param = { id: this.agentuserData.id, type: 0, agentSid: this.agentuserData.agentSid };
          break;
        case 4:
          this.param = { id: this.agentuserData.id, type: 4, agentSid: this.agentuserData.agentSid };
          break;
        default:
          break;
      }
      this.form = {
        userId: '',
        tel: '',
        name: '',
      };
      this.getDetailData(this.param);
    },
    // 筛选
    search() {
      this.currentPage = 1;

      this.getDetailData({
        ...this.param,
        ...this.form,
        pageCurrent: this.currentPage,
        pageSize: 15,
      });
    },
    // 清空
    clear() {
      this.form = {
        tel: '',
        userId: '',
        name: '',
      };

      this.getDetailData({
        ...this.param,
        ...this.form,
        pageCurrent: this.currentPage,
        pageSize: 15,
      });
    },

    // 选择成员
    selectGroup(index) {
      this.active = index;
    },
    // 翻页
    pageChange() {
      this.getDetailData({
        ...this.param,
        ...this.form,
        pageCurrent: this.currentPage,
        pageSize: 15,
      });
    },
  },
};
</script>
<style lang="scss" >
.groupManagement {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    // min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .el-dialog__header {
    text-align: center;
  }
  .el-dialog__body{
      color: #444444;
  }
  .order-detail-content{
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 0 50px;
     .shop-data{
         flex: 1;
     }
        .share-profits{
              display: flex;
              flex-flow: wrap;
          }
      li{
          line-height: 30px;
      }
  }
  .select-group-item{
      margin: 20px auto;
      display: flex;
      flex-flow: wrap;
      justify-content: space-between;
      li{
          text-align: center;
          width: 160px;
          border: 1px solid #DCDFE6;
          line-height: 40px;
           border-radius: 20px;
           cursor: pointer;
      }
  }
}
</style>

