<template>
  <div class="couponList">
    <div class="flexbox flex-lr flex-align-center">
        <el-input v-model="form.couponName" placeholder="请输入优惠券名称" style="width:200px;" clearable></el-input>
        <el-select v-model="form.couponBrand" class="ml20" clearable placeholder="优惠券品牌" style="width:150px;">
          <el-option
            v-for="item in brandOptions"
            :key="item.itemId"
            :label="item.itemValue"
            :value="item.itemId">
          </el-option>
        </el-select>
        <el-select v-model="form.state" class="ml20 mr20" clearable placeholder="优惠券状态" style="width:150px;">
          <el-option
            v-for="item in stateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" @click="search">筛选</el-button>
        <el-button type="info" @click="clear">清除</el-button>
        <PrevReclick>
          <el-button type="primary" @click="navAddCoupon('1')">新增优惠券</el-button>
        </PrevReclick>
    </div>
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column prop="createTime" label="创建时间" align="center">
          <template slot-scope="scope">
            <span>{{$formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm') || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="couponName" label="优惠券名称" align="center"></el-table-column>
        <el-table-column prop="couponName" label="品牌" align="center">
          <template slot-scope="scope">{{getCouponBrand(scope.row.couponBrand)}}</template>
        </el-table-column>
        <el-table-column prop="sellingPrice" label="折扣价" align="center"></el-table-column>
        <el-table-column prop="originalPrice" label="原价" align="center"></el-table-column>
        <el-table-column prop="expiredTime" label="质保期至" align="center">
          <template slot-scope="scope">
            <span>{{$formatDate(scope.row.expiredTime, 'yyyy-MM-dd HH:mm') || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inventory" label="库存" align="center"></el-table-column>
        <el-table-column prop="sellingNum" label="销量" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.sellingNum || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 1">编辑中</span>
            <span v-if="scope.row.state === 2">进行中</span>
            <span v-if="scope.row.state === 3">已下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="上下架" align="center">
          <template slot-scope="scope">
            <el-switch :value="scope.row.state" :active-value="2" :inactive-value="3" :disabled="Date.now() > scope.row.expiredTime"
            @change="upperOrLowerShelves($event, scope.row.couponId)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.state === 1" @click="navAddCoupon('2', scope.row.couponId)">编辑</el-button>
            <el-button type="text" v-else @click="navAddCoupon('3', scope.row.couponId)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import { 
  $sg_getRebateCouponList,
  $sg_getPlatCouponBrandList,
  $sg_exchangeCouponState,
  $sg_initCoupon,
} from '@/api/buyRebate';

export default {
  data() {
    return {
      form: {
        couponBrand: '',
        couponName: '',
        state: '',
      },
      brandOptions: [],
      stateOptions: [
        {
          value: 1,
          label: '编辑中',
        },
        {
          value: 2,
          label: '进行中',
        },
        {
          value: 3,
          label: '已下架',
        },
      ],
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      currentSize: 10,
    };
  },
  created() {
    this.getPlatCouponBrandList();
    this.search();
  },
  methods: {
    // 获取优惠券品牌列表
    getPlatCouponBrandList() {
      $sg_getPlatCouponBrandList().then((res) => {
        this.brandOptions = res;
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        ...this.form,
        currentPage: this.currentPage,
        size: this.currentSize,
      };
      $sg_getRebateCouponList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 清空
    clear() {
      this.form = {
        couponBrand: '',
        couponName: '',
        state: '',
      };
      this.currentPage = 1;
      this.getTableData();
    },
    // 跳转到新增优惠券页面
    navAddCoupon(type, couponId) {
      // type: 类型， 1-新增，2-编辑，3-详情
      if (type === '1') {
        $sg_initCoupon().then((res) => {
          this.$router.push({
            name: 'addCoupon',
            query: {
              couponId: res.id,
              type,
              uploadMemberMain: new Date().getTime(),
            },
          });
        });
      } else {
        this.$router.push({
          name: 'addCoupon',
          query: {
            type,
            couponId,
            uploadMemberMain: new Date().getTime(),
          },
        });
      }
    },
    // 获取优惠券品牌名称
    getCouponBrand(id) {
      const couponBrand = this.brandOptions.filter((val) => val.itemId === id);
      return couponBrand[0].itemValue;
    },
    // 上下架
    upperOrLowerShelves(e, couponId) {
      const params = {
        state: e,
        couponId,
      };
      $sg_exchangeCouponState(params).then(() => {
        this.getTableData();
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.couponList {
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox{
      width: 100%;
      margin-top: 20px;
    }
    .el-table{
      min-height: 580px;
      & > th {
        background-color: #EF3F46;
        color: #fff;
      }
    }

}
</style>
