<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
      <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
        <el-input v-model="form.userId" placeholder="请输入用户ID" class="mr20" style="width:160px;" clearable></el-input>
        <el-input v-model="form.phone" placeholder="请输入用户电话" class="mr20" style="width:160px;" clearable></el-input>
        <el-select v-model="form.level" class="mr20" clearable placeholder="用户类型" style="width:150px;">
          <el-option v-for="item in userLevel" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input v-model="form.agentName" placeholder="请输入归属代理商名称" style="width:200px;" clearable></el-input>
        <el-date-picker v-model="time" class="ml20" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button type="primary" class="sure" @click="search">筛选</el-button>
      <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="key" label="序号" width="200"></el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <template slot-scope="scope">
            <span>{{$formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column>
         <el-table-column prop="memberTime" label="开通会员时间">
          <template slot-scope="scope">
            <span>{{  scope.row.level!=0?$formatDate(scope.row.memberTime, 'yyyy-MM-dd HH:mm:ss'):'' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="用户ID"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="level" label="用户类型">
          <template slot-scope="scope">
            <span>{{(scope.row.level||scope.row.level==0)?userLevel[scope.row.level].label:''}}</span>
          </template> 
        </el-table-column>
        <el-table-column prop="nickName" label="昵称"></el-table-column>
        <el-table-column prop="agentName" label="归属代理商"></el-table-column>
        <el-table-column prop="agentPhone" label="归属代理商电话"></el-table-column>

      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sg_userBuyRebateList,
} from '@/api/buyRebate';

export default {
  data() {
    return {
      form: {
        'agentName': '',
        'level': null,
        'phone': '',
        'userId': '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      userLevel: [{ label: '游客', value: 0 }, { label: '会员', value: 1 }, { label: '达人', value: 2 }, ''],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
   
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        'agentName': '',
        'level': null,
        'phone': '',
        'userId': '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求统计数据
   
    // 请求列表数据
    getTableData() {
      const params = {
        current: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
      if (this.time) {
        params.createTimeStart = (this.time[0]).getTime();
        params.createTimeEnd = (this.time[1]).getTime();
      } else {
        params.createTimeStart = '';
        params.createTimeEnd = '';
      }
      $sg_userBuyRebateList(params).then((res) => {
        this.tableData = res.records;
        if (this.tableData && this.tableData.length) {
          this.tableData.forEach((item, index) => {
            item.key = Number.parseInt(index + 1 + (this.currentPage - 1) * 15, 0);
          });
        }
        this.totalPage = res.pages;
      });
    },
    
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.eatInOrder {
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    // min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>

