<template>
  <div class="addCoupon">
    <div class="basicInfo">
      <div class="title">基础信息</div>
      <div class="flexbox mt20">
        <div class="goods">
          <div>优惠券封面</div>
          <img class="goodsPath" :src="form.couponHead" alt="" v-if="form.couponHead"/>
          <div class="addGoodsPath flexbox flex-align-center flex-justify-center" v-else>
            <i class="el-icon-plus"></i>
          </div>
          <el-upload action="#" :disabled="disabled" :http-request="img_upload" :show-file-list="false">
            <el-button type="primary" class="uploadBtn" :disabled="disabled">选择图片</el-button>
            <div slot="tip" class="tips">参考尺寸：750px x 450px</div>
          </el-upload>
        </div>
        <div class="formBox">
          <div class="flexbox flex-align-center">
            <span class="label">优惠券品牌</span>
            <el-select
              v-model="form.couponBrand"
              style="width: 300px; margin: 0px 10px"
              placeholder="选择品牌"
              :disabled="disabled"
            >
              <el-option
                v-for="item in brandOptions"
                :key="item.itemId"
                :label="item.itemValue"
                :value="item.itemId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">收购价</span>
            <el-input
              v-model="form.purchasePrice"
              type="number"
              style="width: 300px; margin: 0px 10px"
              placeholder="请输入收购价"
              :disabled="disabled"
            ></el-input>
            <span>元</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">原价</span>
            <el-input
              v-model="form.originalPrice"
              type="number"
              style="width: 300px; margin: 0px 10px"
              placeholder="请输入原价"
              :disabled="disabled"
            ></el-input>
            <span>元</span>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">售价</span>
            <el-input
              v-model="form.sellingPrice"
              type="number"
              style="width: 300px; margin: 0px 10px"
              placeholder="请输入售价"
              :disabled="disabled"
            ></el-input>
            <span>元</span>
          </div>
        </div>
        <div class="formBox">
          <div class="flexbox flex-align-center">
            <span class="label">优惠券名称</span>
            <el-input
              v-model="form.couponName"
              style="width: 300px; margin: 0px 10px"
              placeholder="请输入优惠券名称"
              :maxlength="15"
              :disabled="disabled"
            ></el-input>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">质保期至</span>
            <el-date-picker v-model="form.expiredTime" value-format="timestamp" :disabled="disabled" type="date" style="margin: 0px 10px" 
            placeholder="选择时间" :picker-options="datePickerOptions"></el-date-picker>
          </div>
          <div class="flexbox flex-align-center mt30">
            <span class="label">限购规则</span>
            <el-radio
              v-model="form.isLimit"
              :label="0"
              style="margin: 0px 10px"
              :disabled="disabled"
              >不限购</el-radio>
            <el-radio v-model="form.isLimit" :label="1" :disabled="disabled">限购</el-radio>
          </div>
          <div class="flexbox flex-align-center mt30" v-if="form.isLimit === 1">
            <span class="label">每人限购</span>
            <el-input
              v-model="form.singleLimit"
              style="width: 300px; margin: 0px 10px"
              placeholder="请输入每人限购"
              :disabled="disabled"
              type="number"
              oninput="if(value.length > 5) value=value.slice(0, 5)"
            ></el-input>
            <span>份</span>
          </div>
        </div>
      </div>
    </div>
    <div class="couponDetail">
      <div class="title">
        <span>使用须知</span>
        <span>注：优惠券一经购买系统不支持退款操作，使用须知需详情说明</span>
      </div>
      <div class="mt20">
        <TEditor class="editor" type="complete" v-model="form.remark" v-if="flag"/>
      </div>
    </div>
    <div class="couponTable">
      <div class="flexbox flex-align-center flex-justify-between">
        <div class="titleBox">
          <span class="title">优惠券券码</span>
          <el-button type="primary" @click="uploadCoupon" :disabled="disabled">上传券码</el-button>
          <span class="tips">请上传JPEG、JPG、PNG格式的图片（一次上传不得超过5M）</span>
        </div>
        <div class="stock">库存：{{form.inventory || 0}}</div>
      </div>
      <el-table :data="tableDataShow" style="width: 100%" border class="mt10">
        <el-table-column prop="" label="序号" align="center">
          <template slot-scope="scope">{{currentSize * (currentPage - 1) + scope.$index + 1}}</template>
        </el-table-column>
        <el-table-column prop="code" label="券码编号" align="center"></el-table-column>
        <el-table-column prop="state" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 0">未售</span>
            <span v-if="scope.row.state == 1">已售</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text"  v-if="scope.row.state == 0" @click="delCoupon(scope.row.code)" 
            :disabled="disabled">删除</el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt10" background :total="form.couponListVos.length" :page-size="currentSize"
      :current-page.sync="currentPage"></el-pagination>
      <div class="btnBox flexbox flex-align-center flex-justify-center" v-if="!disabled">
        <PrevReclick>
          <el-button class="btn" type="primary" @click="submit(2)">保存并发布</el-button>
        </PrevReclick>
        <PrevReclick>
         <el-button class="btn" type="info" @click="submit(1)">只保存不发布</el-button>
        </PrevReclick>
        <el-button class="btn" type="info" @click="cancel">取消</el-button>
      </div>
    </div>
    <!-- 上传进度弹窗 -->
    <el-dialog
      title="上传进度"
      :visible.sync="uploadProgressDialog"
      center
      :close-on-click-modal="false"
      :show-close="false"
      width="400px">
      <el-progress :percentage="((this.uploadPictureNum / this.selectPictureArr.length) * 100).toFixed(0) * 1" 
        color="#f63d29"></el-progress>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cancelProgress">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 图片xx系统已存在提示弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="imgRepeatDialog"
      center
      :close-on-click-modal="false"
      :show-close="false"
      width="400px">
      <div class="imgRepeatTips" v-if="selectPictureArr && selectPictureArr[uploadPictureNum]">
        图片{{selectPictureArr[uploadPictureNum].name}}系统已存在
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadImgReplace">替换</el-button>
        <el-button type="primary" @click="uploadImgSkip">跳过</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import TEditor from '@/components/common/TEditor.vue';
import FileUploader from '@base/utils/file/FileUploader';
import ChooseFile from '@base/utils/file/ChooseFile';
import { 
  $sg_getPlatCouponBrandList,
  $sg_platCouponDetail,
  $sp_editCouponInfo,
  $sp_addCoupon,
  $sg_delCoupon,
  $sg_delCouponInfo,
  $sg_delCouponInfoMain,

} from '@/api/buyRebate';

export default {
  data() {
    return {
      type: '', // 1-新增，2-编辑，3-详情
      flag: false,
      disabled: false,
      datePickerOptions: {
        disabledDate(time) {
          return time.getTime() < (Date.now() - 24 * 3600 * 1000);
        },
      },
      form: {
        id: '',
        couponHead: '',
        couponBrand: '',
        purchasePrice: '',
        originalPrice: '',
        sellingPrice: '',
        couponName: '',
        expiredTime: '',
        isLimit: 1,
        singleLimit: '',
        remark: '',
        couponListVos: [],
      },
      brandOptions: [],
      tableData: [],
      currentPage: 1,
      currentSize: 10,
      uploadProgressDialog: false, // 上传图片进度弹窗
      selectPictureArr: 0, // 选择上传的图片
      uploadPictureNum: 0, // 当前待上传第几张图片
      uploadPictuerFlag: true, // 上传图片控制器
      uploadMember: '', // 标识码-本次循环上传的标识
      uploadMemberMain: '', // 标识码-本次页面上传的标识
      isSave: false, // 判断当前页面是否保存
      imgRepeatDialog: false, // 图片xx系统已存在提示弹窗
    };
  },
  components: {
    TEditor,
  },
  computed: {
    // 前端分页显示的当前券码列表数据
    tableDataShow() {
      return this.form.couponListVos.slice((this.currentPage - 1) * this.currentSize, this.currentPage * this.currentSize);
    },
  },
  created() {
    // type: 类型， 1-新增，2-编辑，3-详情
    const { couponId, type, uploadMemberMain } = this.$route.query;
    this.type = type;
    this.form.id = couponId;
    this.uploadMemberMain = uploadMemberMain;
    this.getPlatCouponBrandList();
    this.platCouponDetail();
    if (type === '3') {
      this.disabled = true;
    }
  },
  methods: {
    // 获取优惠券品牌列表
    getPlatCouponBrandList() {
      $sg_getPlatCouponBrandList().then((res) => {
        this.brandOptions = res;
      });
    },
    // 获取详情
    platCouponDetail() {
      const params = {
        couponId: this.form.id,
      };
      $sg_platCouponDetail(params).then((res) => {
        this.flag = true;
        if (this.type === '1') {
          this.form.id = res.id;
          this.form.couponListVos = res.couponListVos;
        } else {
          this.form = res;
        }
        // 重新计算库存
        const arr = this.form.couponListVos.filter((val) => val.state === 0);
        this.form.inventory = arr.length;
      });
    },
    // 获取优惠券券码列表
    getCouponCodeList() {
      const params = {
        couponId: this.form.id,
      };
      $sg_platCouponDetail(params).then((res) => {
        this.form.couponListVos = res.couponListVos;
        const arr = this.form.couponListVos.filter((val) => val.state === 0);
        this.form.inventory = arr.length;
      });
    },
    // 上传优惠券封面
    async img_upload(file) {
      const arr = file.file.name.split('.');
      const fileUploder = new FileUploader(file.file, {
        addLibrary: false,
        compress: true,
        ext: arr[arr.length - 1],
      });
      fileUploder
        .upload()
        .then((url) => {
          this.form.couponHead = url;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    // 上传券码
    uploadCoupon() {
      // 选择图片
      const choseFile = new ChooseFile({
        accept: 'image/*',
        multiple: true,
        directory: false,
      });
      choseFile.chooseImage((res) => {
        console.log(res[0]);
        this.selectPictureArr = [];
        let format = true; // 校验上传的图片格式是否正确
        let totalSize = 0; // 一次上传的图片总大小
        const imgFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        for (let i = 0; i < res.length; i += 1) {
          if (imgFormats.indexOf(res[i].type) === -1) {
            format = false;
            break;
          }
          totalSize += res.size;
          this.selectPictureArr.push(res[i]);
        }
        if (!format) {
          this.$message.error('图片格式不正确');
          return;
        }
        if (totalSize > 5 * 1024 * 1024) {
          this.$message.error('上传图片超过5M，请重新上传');
          return;
        }
        this.uploadPictureNum = 0;
        this.uploadPictuerFlag = true;
        this.uploadMember = new Date().getTime();
        this.uploadProgressDialog = true;
        this.addCoupon();
      });
    },
    // 上传图片封装
    addCoupon() {
      const addCouponPromise = new Promise((resolve, reject) => {
        const formdata = new FormData();
        formdata.append('file', this.selectPictureArr[this.uploadPictureNum]);
        formdata.append('couponId', this.form.id);
        formdata.append('uploadMember', this.uploadMember);
        formdata.append('uploadMemberMain', this.uploadMemberMain);
        $sp_addCoupon(formdata).then((res) => {
          if (res.uploadState === 0) { // 图片已存在
            this.imgRepeatDialog = true;
          } else if (res.uploadState === 1) { // 上传成功
            this.uploadPictureNum += 1;
            resolve(this.addCoupon);
          } else if (res.uploadState === 2) { // 上传失败
            reject();
          }
        }).catch((err) => {
          reject(err);
        });
      });
      addCouponPromise.then((res) => {
        if (!this.uploadPictuerFlag) return;
        if (this.uploadPictureNum >= this.selectPictureArr.length) {
          this.uploadProgressDialog = false;
          this.$message.success('上传成功');
          this.getCouponCodeList();
          return;
        }
        res();
      });
    },
    // 替换
    uploadImgReplace() {
      const choseFile = new ChooseFile({
        accept: 'image/*',
        multiple: false,
        directory: false,
      });
      const imgFormats = ['image/jpeg', 'image/jpg', 'image/png'];
      choseFile.chooseImage((k) => {
        if (imgFormats.indexOf(k.type) === -1) {
          this.$message.error('图片格式不正确');
          return;
        }
        if (k.size > 5 * 1024 * 1024) {
          this.$message.error('上传图片超过5M，请重新上传');
          return;
        }
        this.imgRepeatDialog = false;
        this.selectPictureArr[this.uploadPictureNum] = k;
        this.addCoupon();
      });  
    },
    // 跳过
    uploadImgSkip() {
      this.imgRepeatDialog = false;
      this.uploadPictureNum += 1;
      if (this.uploadPictureNum >= this.selectPictureArr.length) {
        this.uploadProgressDialog = false;
        this.getCouponCodeList();
      } else {
        this.addCoupon();
      }
    },
    // 取消上传优惠券确认框
    cancelProgress() {
      this.uploadPictuerFlag = false;
      this.$confirm('取消进度即放弃本次上传所有图片', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        center: true,
      }).then(() => {
        this.uploadProgressDialog = false;
        const params = {
          couponId: this.form.id,
          uploadMember: this.uploadMember,
        };
        $sg_delCouponInfo(params).then(() => {
          this.uploadProgressDialog = false;
          this.getCouponCodeList();
        });
      }).catch(() => {
        this.uploadPictuerFlag = true;
        this.addCoupon();
      });
    },
    // 删除优惠券
    delCoupon(code) {
      this.$confirm('删除后库存会对应减少，确认删除?', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        center: true,
      }).then(() => {
        const params = {
          code,
          couponId: this.form.id,
        };
        $sg_delCoupon(params).then(() => {
          this.getCouponCodeList();
        });
      }).catch(() => {});
    },
    // 表单校验
    async checkForm() {
      const form = this.form;
      await this.$validator(
        {
          ...form,
        },
        [
          'couponHead/require/优惠券封面',
          'couponBrand/require/优惠券品牌',
          'purchasePrice/require/收购价',
          'originalPrice/require/原价',
          'sellingPrice/require/售价',
          'couponName/require/优惠券名称',
          'expiredTime/require/质保期',
          'remark/require/使用须知',
        ],
      );
      if (+form.purchasePrice !== (+form.purchasePrice).toFixed(2) * 1) {
        this.$message.error('收购价最多保留两位小数');
        return false;
      }
      if (+form.originalPrice !== (+form.originalPrice).toFixed(2) * 1) {
        this.$message.error('原价最多保留两位小数');
        return false;
      }
      if (+form.sellingPrice <= 0) {
        this.$message.error('售价必须大于0');
        return false;
      }
      if (+form.sellingPrice !== (+form.sellingPrice).toFixed(2) * 1) {
        this.$message.error('售价最多保留两位小数');
        return false;
      }
      if (+form.originalPrice <= +form.sellingPrice) {
        this.$message.error('原价不得小于等于售价，请重新输入');
        return false;
      }
      if (+form.sellingPrice < +form.purchasePrice) {
        this.$message.error('售价不得小于收购价，请重新输入');
        return false;
      }
      if (form.isLimit === 1 && !form.singleLimit) {
        this.$message.error('每人限购不能为空');
        return false;
      }
      if (form.isLimit === 1 && form.singleLimit % 1 !== 0) {
        this.$message.error('每人限购必须为整数');
        return false;
      }
      if (form.couponListVos.length === 0) {
        this.$message.error('优惠券券码不能为空');
        return false;
      }
      return true;
    },
    // 保存
    async submit(type) {
      if (!(await this.checkForm())) return; // 校验输入数据
      const params = {
        id: this.form.id,
        couponHead: this.form.couponHead,
        couponBrand: this.form.couponBrand,
        originalPrice: +this.form.originalPrice,
        sellingPrice: +this.form.sellingPrice,
        purchasePrice: +this.form.purchasePrice,
        couponName: this.form.couponName,
        expiredTime: this.form.expiredTime,
        isLimit: this.form.isLimit,
        singleLimit: this.form.singleLimit,
        remark: this.form.remark,
        saveState: type, // 1-保存并发布，2-只保存不发布
        state: type,
        uploadMember: this.uploadMember,
        uploadMemberMain: this.uploadMemberMain,
      };
      $sp_editCouponInfo(params).then(() => {
        this.isSave = true;
        this.$router.go(-1);
      });
    },
    // 取消
    cancel() {
      this.$confirm('取消将不会保存编辑信息，确认取消?', '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        showClose: false,
        center: true,
      }).then(() => {
        this.$router.go(-1);
      }).catch(() => {});
    },
  },
  // 导航离开该组件的对应路由时调用
  beforeRouteLeave(to, from, next) {
    if (this.isSave) {
      next();
    } else {
      const params = {
        couponId: this.form.id,
        uploadMember: this.uploadMember,
        uploadMemberMain: this.uploadMemberMain,
      };
      $sg_delCouponInfoMain(params).then(() => {
        next();
      });
    }
  },
};
</script>
<style lang="scss">
.addCoupon {
  width: 100%;
  .basicInfo {
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;
    color: #666;
    background-color: #fff;
    overflow: auto;
    .title {
      font-size: 15px;
      font-weight: bold;
    }
    .goods {
      width: 240px;
      font-size: 14px;
      .goodsPath {
        width: 170px;
        height: 170px;
        border-radius: 8px;
        margin-top: 10px;
      }
      .addGoodsPath {
        width: 170px;
        height: 170px;
        border-radius: 8px;
        margin-top: 10px;
        border: 1px dashed #999;
        background-color: #f5f5f5;
        font-size: 50px;
        color: #999;
      }
      .uploadBtn {
        margin-left: 40px;
        margin-top: 20px;
      }
      .tips {
        margin-left: 10px;
        margin-top: 10px;
      }
    }
    .formBox {
      margin-left: 40px;
      .label {
        width: 100px;
        text-align: right;
      }
      .tips {
        width: 350px;
        margin-left: 90px;
        font-size: 12px;
        color: #f63d29;
        margin-top: 10px;
      }
      .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .couponDetail {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .title span:first-child {
      font-size: 15px;
      font-weight: bold;
    }
    .title span:last-child {
      font-size: 12px;
      color: #ef3f46;
      margin-left: 10px;
    }
  }
  .couponTable {
    width: 100%;
    border-radius: 12px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    .titleBox .title {
      font-size: 15px;
      font-weight: bold;
      margin-right: 15px;
    }
    .titleBox .tips {
      font-size: 12px;
      color: #999;
      margin-left: 15px;
    }
    .stock {
      color: #999;
    }
    .btnBox {
      width: 100%;
      margin-top: 20px;
      .btn {
        width: 290px;
        height: 60px;
        border-radius: 60px;
      }
    }
  }
  .imgRepeatTips {
    width: 100%;
    text-align: center;
  }
}
</style>
