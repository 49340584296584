<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <el-input v-model="form.shopName" placeholder="请输入商户名称" class="mr20" style="width:160px;" clearable></el-input>
           <el-input v-model="form.phone" placeholder="请输入商户电话" class="mr20" style="width:160px;" clearable></el-input>
           <el-input v-model="form.agentName" placeholder="请输入归属代理商名称" style="width:200px;" clearable></el-input>
        </div>
        <el-button type="primary" class="sure" @click="search">筛选</el-button>
        <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="key" label="序号" width="200"></el-table-column>
        <!-- <el-table-column prop="shopId" label="店铺ID"></el-table-column> -->
        <el-table-column prop="shopName" label="商户名称"></el-table-column>
        <el-table-column prop="phone" label="账号"></el-table-column>
        <el-table-column prop="createTime" label="开通返利时间">
          <!-- <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.payTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template> -->
        </el-table-column>
          <el-table-column prop="agentName" label="归属代理商"></el-table-column>
           <el-table-column prop="agentPhone" label="归属代理商电话"></el-table-column>
        
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sg_shopBuyRebateList, 
} from '@/api/buyRebate';

export default {
  data() {
    return {
      form: {
        agentName: '',
        phone: '',
        shopName: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
    
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
   
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        agentName: '',
        phone: '',
        shopName: '',
      };
      this.time = '';
      this.getTableData();
    },
   
    // 请求列表数据
    getTableData() {
      const params = {
        current: this.currentPage,
        pageSize: 15,
        ...this.form,
      };
     
      // 请求订单数据
      $sg_shopBuyRebateList(params).then((res) => {
        this.tableData = res.records;
        if (this.tableData && this.tableData.length) {
          this.tableData.forEach((item, index) => {
            item.key = Number.parseInt(index + 1 + (this.currentPage - 1) * 15, 0);
          });
        }
        this.totalPage = res.pages;
      });
    },
 
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.eatInOrder{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail{
    width: 100%;
    margin-top: 40px;
    padding:20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child{
      margin-right: 80px;
    }
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    // min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>

